import { createFeature, createReducer, on } from '@ngrx/store'
import {
  ProfileCreateState,
  ProfileDetailsState,
  ProfilesState,
  ProfileState,
  ProfileUpdateState,
} from '../models/profiles.model'
import {
  profileActions,
  profileCreateActions,
  profileDetailsActions,
  profilesActions,
  profileUpdateActions,
} from './actions'

const initialProfileState: ProfileState = {
  isLoading: false,
  profile: null,
}

const profileFeature = createFeature({
  name: 'profile',
  reducer: createReducer(
    initialProfileState,
    on(profileActions.requestByUid, (state) => ({ ...state, isLoading: true })),
    on(profileActions.profileByUidSuccess, (state, event) => ({
      ...state,
      isLoading: false,
      profile: event.profile,
    })),
  ),
})

const initialProfilesState: ProfilesState = {
  isLoading: false,
  profiles: [],
}

const profilesFeature = createFeature({
  name: 'profiles',
  reducer: createReducer(
    initialProfilesState,
    on(profilesActions.requestProfiles, (state) => ({ ...state, isLoading: true })),
    on(profilesActions.requestProfilesSuccess, (state, event) => ({
      ...state,
      isLoading: false,
      profiles: event.profiles,
    })),
  ),
})

const initialProfileCreateState: ProfileCreateState = {
  isLoading: false,
  error: '',
  profile: null,
}

const initialProfileUpdateState: ProfileUpdateState = {
  updateProfileIsLoading: false,
  updateProfileError: '',
  updateProfileSuccess: false,
}

const initialProfileDetailsState: ProfileDetailsState = {
  profileDetailsIsLoading: false,
  profileDetailsError: '',
  profileDetailsSuccess: false,
  profileDetails: null,
}

const profileCreateFeature = createFeature({
  name: 'createProfile',
  reducer: createReducer(
    initialProfileCreateState,
    on(profileCreateActions.requestCreateProfile, (state: ProfileCreateState) => ({ ...state, isLoading: true })),
    on(profileCreateActions.requestCreateProfileSuccess, (state: ProfileCreateState, event) => ({
      ...state,
      isLoading: false,
      profile: event.profile,
    })),
    on(profileCreateActions.requestCreateProfileFailed, (state: ProfileCreateState, event) => ({
      ...state,
      isLoading: false,
      error: event.error,
    })),
    on(profileCreateActions.resetCreateProfileState, (state: ProfileCreateState, event) => initialProfileCreateState),
  ),
})

const profileUpdateFeature = createFeature({
  name: 'updateProfile',
  reducer: createReducer(
    initialProfileUpdateState,
    on(profileUpdateActions.requestUpdateProfile, (state: ProfileUpdateState) => ({
      ...state,
      updateProfileIsLoading: true,
    })),
    on(profileUpdateActions.requestUpdateProfileSuccess, (state: ProfileUpdateState, event) => ({
      ...state,
      updateProfileIsLoading: false,
      updateProfileSuccess: true,
    })),
    on(profileUpdateActions.requestUpdateProfileFailed, (state: ProfileUpdateState, event) => ({
      ...state,
      updateProfileIsLoading: false,
      updateProfileError: event.error,
    })),
    on(profileUpdateActions.resetUpdateProfileState, (state: ProfileUpdateState, event) => initialProfileUpdateState),
  ),
})

const profileDetailsFeature = createFeature({
  name: 'profileDetails',
  reducer: createReducer(
    initialProfileDetailsState,
    on(profileDetailsActions.requestProfileById, (state: ProfileDetailsState) => ({
      ...state,
      profileDetailsIsLoading: true,
    })),
    on(profileDetailsActions.requestProfileByIdSuccess, (state: ProfileDetailsState, event) => ({
      ...state,
      profileDetailsIsLoading: false,
      profileDetailsSuccess: true,
      profileDetails: event.profile,
    })),
    on(profileDetailsActions.requestProfileByIdFailed, (state: ProfileDetailsState, event) => ({
      ...state,
      profileDetailsIsLoading: false,
      profileDetailsError: event.error,
    })),
    on(profileDetailsActions.resetProfileByIdState, (state: ProfileDetailsState, event) => initialProfileDetailsState),
  ),
})

export const { name: profileFeatureKey, reducer: profileReducer } = profileFeature
export const { name: profilesFeatureKey, reducer: profilesReducer } = profilesFeature

export const {
  name: profileCreateFeatureKey,
  reducer: profileCreateReducer,
  selectIsLoading,
  selectError,
  selectProfile,
} = profileCreateFeature

export const {
  name: profileUpdateFeatureKey,
  reducer: profileUpdateReducer,
  selectUpdateProfileIsLoading,
  selectUpdateProfileError,
  selectUpdateProfileSuccess,
} = profileUpdateFeature

export const {
  name: profileDetailsFeatureKey,
  reducer: profileDetailsReducer,
  selectProfileDetailsIsLoading,
  selectProfileDetailsError,
  selectProfileDetailsSuccess,
  selectProfileDetails,
} = profileDetailsFeature
