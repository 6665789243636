import { EventEmitter, Injectable, OnDestroy } from '@angular/core'
import {
  BehaviorSubject,
  combineLatest,
  combineLatestAll,
  map,
  Observable,
  Subject,
  Subscriber,
  Subscription,
  takeUntil,
} from 'rxjs'
import {
  Profile,
  ProfileCreateState,
  ProfileDto,
  ProfilesState,
  ProfileUpdateDto,
  ProfileUpdateState,
} from '../models/profiles.model'
import { Store } from '@ngrx/store'
import { selectProfileCreateState, selectProfilesState } from '../store/selectors'
import { profileCreateActions, profilesActions, profileUpdateActions } from '../store/actions'
import { catchError, tap } from 'rxjs/operators'
import {
  selectError,
  selectIsLoading,
  selectProfile,
  selectUpdateProfileError,
  selectUpdateProfileIsLoading,
  selectUpdateProfileSuccess,
} from '../store/reducers'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({ providedIn: 'root' })
export class ProfilesTableService implements OnDestroy {
  isTblLoading = false
  dataChange: BehaviorSubject<Profile[]> = new BehaviorSubject<Profile[]>([])
  // Temporarily stores data from dialogs
  dialogData!: Profile

  data$: Observable<any>
  updateData$: Observable<any>

  destroy$ = new Subject<void>()

  constructor(
    private store: Store<{ profiles: ProfilesState }>,
    private crateStore: Store<{
      profileCreate: ProfileCreateState
    }>,
    private updateStore: Store<{
      profileUpdate: ProfileUpdateState
    }>,
  ) {
    this.data$ = combineLatest({
      isLoading: this.crateStore.select(selectIsLoading),
      error: this.crateStore.select(selectError),
      profile: this.crateStore.select(selectProfile),
    })

    this.updateData$ = combineLatest({
      isLoading: this.updateStore.select(selectUpdateProfileIsLoading),
      error: this.updateStore.select(selectUpdateProfileError),
      success: this.updateStore.select(selectUpdateProfileSuccess),
    })

    this.updateData$.pipe(
      tap((updateState) => {
        if (updateState.success) {
          //TODO: show snackbar
        }
      }),
    )
  }
  get data(): Profile[] {
    return this.dataChange.value
  }
  getDialogData() {
    return this.dialogData
  }
  /** CRUD METHODS */
  getAllProfiles(): void {
    this.store.dispatch(profilesActions.requestProfiles())
    this.store
      .select(selectProfilesState)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (state) => {
          // this.isTblLoading = state.isLoading
          this.dataChange.next(state.profiles)
        },
      })
  }
  addProfile(profile: ProfileDto): void {
    this.store.dispatch(profileCreateActions.requestCreateProfile({ dto: profile }))
  }

  ngOnDestroy() {
    // this.destroy$.next()
    this.isTblLoading = false
    this.destroy$.complete()
  }
}
